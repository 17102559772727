@use '/src/styles/vars' as *;

.icon {width: $icon-size; height: $icon-size; display: inline-block;
  background-image: url(../../../assets/images/icons.svg);
  background-position: var(--bg-position); vertical-align: middle;
  &-mask {mask-image: url(../../../assets/images/icons.svg); mask-position: var(--bg-position);
    mask-repeat: no-repeat;
    &-black {background: $primary-text;}
    &-gray {background: $secondary-text;}
    &-blue {background: $secondary;}
    &-white {background: $primary-bg;}
    &-dark-yellow {background: $dark-yellow;}
    &-yellow {background: $yellow;}
  }
  &-arrow {
    &-left {--bg-position: -303px -187px;}
    &-right {--bg-position: -423px -386px;}
  }
  &-show {--bg-position: -383px -186px;}
  &-hide {--bg-position: -423px -186px;}
  &-tick {--bg-position: -383px -266px;
    &-small {--bg-position: -303px -266px;}
  }
  &-attention {--bg-position: -423px -266px;}
  &-close {--bg-position: -343px -187px;
    &-small {--bg-position: -351px -316px;}
  }
  &-profile {--bg-position: -343px -146px;}
  &-dropdown {--bg-position: -383px -146px;}
  &-location {--bg-position: -263px -306px;
    &-small {--bg-position: -528px -147px;}
  }
  &-bed {--bg-position: -303px -306px;}
  &-message {--bg-position: -462px -306px;}
  &-email {--bg-position: -463px -145px;}
  &-instagram {--bg-position: -179px -482px;
    &-filled {--bg-position: -102px -481px;}
  }
  &-filters {--bg-position: -303px -147px;}
  &-discovery {--bg-position: -462px -227px;}
  &-plus {--bg-position: -383px -227px;}
  &-minus {--bg-position: -343px -227px;}
  &-facebook {--bg-position: -62px -386px;}
  &-search {--bg-position: -303px -226px;}
  &-pin {--bg-position: -263px -146px;}
  &-home {--bg-position: -423px -226px;
    &-small {--bg-position: -395px -572px;}
  }
  &-time {--bg-position: -263px -266px;
    &-small {--bg-position: -505px -147px;}
  }
  &-copy {--bg-position: -423px -306px;}
  &-maximize {--bg-position: -303px -346px;}
  &-right {--bg-position: -463px -346px;}
  &-calendar {--bg-position: -383px -306px;
    &-digit {--bg-position: -383px -386px;}
  }
  &-crown {--bg-position: -263px -426px;
    &-filled {--bg-position: -462px -387px;}
  }
  &-gift {--bg-position: -263px -387px;}
  &-timer {--bg-position: -303px -387px;}
  &-wrench {--bg-position: -303px -426px; width: 32px; height: 32px;}
  &-lock {--bg-position: -343px -346px;
    &-close {--bg-position: -343px -387px;}
  }
  &-users {--bg-position: -343px -427px;}
  &-logo {--bg-position: -423px -427px;
    &-bold {--bg-position: -134px -561px;}
  }
  &-image {--bg-position: -382px -466px;}
  &-map {--bg-position: -463px -427px;}
  &-list {--bg-position: -304px -466px;}
  &-unlock {--bg-position: -383px -346px;}
  &-whatsapp {--bg-position: -139px -482px;}
  &-camera {--bg-position: -343px -520px;}
  &-scan {--bg-position: -263px -519px;}
  &-globe {--bg-position: -383px -519px;}
  &-smartphone {--bg-position: -303px -519px;}
  &-export {--bg-position: -438px -562px;}
}
