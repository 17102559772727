@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'), local('Inter Regular'),
  url('../assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter-Medium'), local('Inter Medium'),
  url('../assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter-Bold'), local('Inter Bold'),
  url('../assets/fonts/Inter-Bold.ttf') format('truetype');
}
