@use 'fonts';
@use 'vars' as *;

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

html, body {padding: 0; margin: 0; font: 400 16px/1.6 $font; letter-spacing: -0.01rem;
  color: $primary-text;
}

html {min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

p, h1, h2, h3, h4 {margin: 0;}
h1, h2, h3, h4 {line-height: 1.2; font-weight: 700; letter-spacing: -0.02rem;}
h1 {font-size: $title-large;}
h2 {font-size: $title-medium;}
h3 {font-size: $title-small;}

a {cursor: pointer; text-decoration: none;}

ol {padding: 0; margin: 0;}

// Content
.content {padding: 0 $side-padding; max-width: 1240px; margin: 0 auto;}

// Form
.form {
  &-label {position: absolute; left: 1rem; top: 1.125rem;
    color: $secondary-text; pointer-events: none; transition: all 0.2s;
  }
  &-group {position: relative; margin-bottom: 1.25rem; text-align: left;
    &.active {
      .form-label {transform-origin: left top; transform: scale(0.87) translateY(-0.625rem);}
      .form-currency ~ .form-label {}
    }
    &-error {font-size: $small; color: $error; margin-top: 0.25rem;}
  }
  &-control {background: $secondary-bg; border: 1px solid transparent;
    border-radius: 16px; margin: 0; padding: 1.125rem 1rem; font-size: $default;
    line-height: 1.6; color: $primary-text; width: 100%; box-sizing: border-box;
    letter-spacing: -0.01rem; font-family: $font;
    &:focus-visible {outline: none;}
    &-labeled {padding-top: 1.75rem; padding-bottom: 0.5rem;}
    &::placeholder {color: $secondary-text; font-family: $font;}
    &-error {background: $light-error; border-color: $error;}
  }
  &-icon {width: $icon-size; height: $icon-size; position: absolute; right: 1rem; top: 1.125rem;
    & ~ .form-control {padding-right: calc(1rem + 24px);}
  }
  &-currency {position: absolute; left: 1rem; top: 1.2rem;
    & ~ .form-control {padding-left: 2rem;}
    & ~ .form-label {left: 2rem;}
  }
  &-actions {margin-top: 2.5rem;}
}

// Columns
.row {margin: 0 -8px; display: flex; flex-wrap: wrap;}
.col {padding: 0 8px; box-sizing: border-box;
  &-50 {width: 50%; flex: 0 0 50%;}
}

/* Toast */
.Toastify {
  --toastify-text-color-light: #242424;
  --toastify-toast-min-height: 5.25rem;
  --toastify-toast-width: 335px;
  --toastify-font-family: $font;

  &__toast {border-radius: 12px; box-shadow: 0 12px 25px #3F424411; padding: 1.125rem 3rem 1.125rem 1.125rem; margin-top: 1rem;
    align-items: center; position: relative;
    &-container {bottom: 2.5rem; padding-left: 0.5rem; padding-right: 0.5rem; box-sizing: border-box;
      &:has(.Toastify__toast-icon) {padding-left: $side-padding; padding-right: $side-padding;}
    }
    &-close {position: absolute; right: 0.5rem; top: 0.5rem;}
    &-icon {width: 44px; margin-inline-end: 1rem;}
    &:has(.Toastify__toast-icon) {border-radius: 16px; padding: 1.25rem;
      div .Toastify__toast-close {right: 1rem; top: 50%; transform: translateY(-50%);}
    }
  }
}

.action-sheet + div {-ms-overflow-style: none; scrollbar-width: none;
  &::-webkit-scrollbar {width: 0; -webkit-appearance: none; display: none;}
}

:root {
  --rt-color-dark: $primary;
}
