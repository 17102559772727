@use '/src/styles/vars' as *;

.link {text-decoration: none; color: $secondary; transition: all 0.6s; line-height: $icon-size;
  display: inline-block;
  &:hover {color: #1069A9; text-decoration: none; transition: all 0.2s;}
  &:active {color: #0C5A93; text-decoration: none; transition: all 0.2s;}
  &-bold {font-weight: 700;}
  &.loading {pointer-events: none; position: relative; color: transparent;
    &::after {position: absolute; width: 29px; height: 29px;
      animation: spin 1s linear infinite; content: ""; top: 0; left: 0;
      bottom: 0; right: 0; margin: auto; border-radius: 50%; pointer-events: none;
      border: $secondary 2px solid; border-top-color: rgba($secondary, 0.2);
    }
  }
  &-dangerous {color: $error;
    &:hover {color: #C72033;}
    &:active {color: #B31B2C;}
    &.loading::after {border-color: $error; border-top-color: rgba($error, 0.2);}
  }
}
