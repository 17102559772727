@use '/src/styles/vars' as *;

$border-width: 4px;
$border-length: 64px;

.desktop-warning-modal {
  background: linear-gradient(rgba($light-beige, 0.5), rgba($light-beige, 0.5)),
  url('../../../assets/images/desktop-warning-overlay.jpg'); background-size: cover;
  position: relative; display: flex; align-items: center; justify-content: center;
  &-header {background: $primary; height: 33vh; padding: 32px 0; box-sizing: border-box;
    position: absolute; top: 0; left: 0; width: 100%;
  }
  &-main {z-index: 5; width: 100%;}
  &-card {background: $primary-bg; box-shadow: 0 12px 25px #3F424412; border-radius: 16px;
    padding: 54px 9%; display: flex; align-items: center; justify-content: space-between;
    @media (max-width: $tablet) {padding: 28px 4%;}
    @media (max-width: $mobile) {flex-direction: column;}
  }
  &-content {flex: 0 0 40%;
    @media (max-width: $tablet) {flex: 0 0 45%;}
    @media (max-width: $mobile) {margin-bottom: 32px;}
  }
  &-title {font-size: $title-largest; margin-bottom: 1.5rem;
    @media (max-width: $tablet) {font-size: $title-large;}
  }
  &-description {margin-bottom: 0.75rem;}
  &-email {display: flex; align-items: center; justify-content: space-between; margin-top: 1.5rem;}
  &-qr {aspect-ratio: 1; display: flex; align-items: center; justify-content: center;
    position: relative; flex: 0 0 374px;
    @media (max-width: $tablet) {flex: 0 0 280px;}
    &::before {content: ""; position: absolute; inset: 0; background: $tertiary-bg;
      padding: $border-width; border-radius: 24px;
      mask:
        linear-gradient(0deg, #000 calc(2 * $border-width), #0000 0) 50% $border-width/calc(100% - 2 * $border-length) 100% repeat-y,
        linear-gradient(-90deg,#000 calc(2*$border-width),#0000 0) $border-width 50%/100% calc(100% - 2*$border-length) repeat-x,
        linear-gradient(#000 0 0) content-box,
        linear-gradient(#000 0 0);
      mask-composite: exclude;
    }
  }
  &-footer {position: absolute; bottom: 48px; left: 50%; transform: translateX(-50%); text-align: center;
    color: $tertiary-text; width: 215px; font-size: $small;
    a {line-height: 1.6;}
  }
  &-copyright {margin-top: 1rem;}
}
