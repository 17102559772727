@use '/src/styles/vars' as *;

.modal {position: absolute; width: 335px; top: 50%; left: 50%; transform: translate(-50%, -50%);
  border-radius: 16px; overflow: visible; box-shadow: 0 6px 30px rgba(#182126, 0.08);
  background: $primary-bg;
  &:focus-visible {outline: none;}
  &-fullscreen {width: 100%; min-height: 100vh; top: 0; left: 0; transform: none;
    border-radius: 0;
  }
  &-overlay {background: rgba($primary, 0.2);  z-index: 102; position: fixed;
    inset: 0; overflow: auto;
  }
}
