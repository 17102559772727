/* app font */
$font: Inter, Arial, sans-serif;

/* colors */
$primary: #242424;
$primary-text: #141E23;
$primary-bg: #FFFFFF;
$secondary: #1781CE;
$secondary-text: #8E9396;
$secondary-bg: #F5F8FA;
$tertiary-text: #CFCFCF;
$tertiary-bg: #D9DCE3;
$error: #ec2a41;
$light-error: #FEE9EB;
$success: #0E9766;
$light-success: #B6F2D4;
$light-beige: #F9F7F1;
$light-yellow: #FFEDC3;
$yellow: #F2C04C;
$dark-yellow: #BE8B12;

/* font-sizes */
$title-largest: 3rem; /* 48px */
$title-larger: 2.25rem; /* 36px */
$title-large: 1.5rem; /* 24px */
$title-medium: 1.3rem; /* 21px */
$title-small: 1.125rem; /* 18px */
$large: 1.75rem; /* 28px */
$default: 1rem; /* 16px */
$small: 0.8rem; /* 13px */
$smallest: 0.625rem; /* 10px */

/* sizes */
$icon-size: 24px;
$side-padding: 1.25rem; /* 20px */
$tab-bar-height: 50px;
$header-vertical-padding: 1.125rem;
$header-height: calc(2 * $header-vertical-padding + $icon-size);

/* screen-sizes */
$mobile: 767px;
$tablet: 1024px;
